import React, { useState } from 'react';
import { Table, Space, Button, Tooltip, Dropdown, Tag, Typography } from 'antd';
import {
  FormOutlined,
  LinkOutlined,
  DownOutlined,
  PhoneOutlined,
  VideoCameraOutlined,
  PlusOutlined,
  DeploymentUnitOutlined,
  LineOutlined,
} from '@ant-design/icons';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { usePatientStore, useModalStore, useFilterStore } from '../store';
import LinkPatientModal from './LinkPatientModal';
import { MODAL_TYPES } from '../constants';
import { parseFieldSetup, translateStatus } from '../utils/stringUtils';

dayjs.extend(utc);
const { Text } = Typography;

const PatientTable = ({ userInfo, messageApi }) => {
  const {
    activeCalls,
    validPatients,
    isFetchingPatients,
    onSelectPatient,
    selectedPatient,
  } = usePatientStore((state) => ({
    activeCalls: state.activeCalls,
    isFetchingPatients: state.isFetchingPatients,
    validPatients: state.validPatients,
    onSelectPatient: state.onSelectPatient,
    selectedPatient: state.selectedPatient,
  }));

  console.log('activeCalls', activeCalls);
  const { filters } = useFilterStore((state) => ({
    filters: state.filters,
  }));

  const { updateModalStatus } = useModalStore((state) => ({
    updateModalStatus: state.updateModalStatus,
  }));

  const [, setSelectedRecord] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const showModal = (record) => {
    onSelectPatient(record);
    setSelectedRecord(record);
    setIsOpen(true);
  };

  const HISTORY_TABLE_COLUMNS = (showModal) => [
    {
      title: 'Patient name',
      key: 'patientFullName',
      render: (_, record) => (
        <span>{`${record?.patientFirstName ? ' ' + parseFieldSetup(record.patientFirstName) : ''}${record?.patientLastName ? ' ' + parseFieldSetup(record.patientLastName) : ''}`}</span>
      ),
      sorter: (a, b) =>
        parseFieldSetup(a.patientFirstName)
          ?.charAt(0)
          ?.localeCompare(parseFieldSetup(b.patientFirstName)?.charAt(0)) ?? -1,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Sitecode',
      key: 'sitecode',
      sorter: (a, b) =>
        parseFieldSetup(a.sitecode)
          ?.charAt(0)
          ?.localeCompare(parseFieldSetup(b.sitecode)?.charAt(0)) ?? -1,
      sortDirections: ['ascend', 'descend'],
      render: (_, record) => <span>{parseFieldSetup(record?.sitecode)}</span>,
    },
    {
      title: 'Date Time Call connected',
      key: 'createTime',
      width: 180,
      render: (_, record) => (
        <span>
          {record?.createTime
            ? dayjs(record?.createTime).utc().format('DD MMM YYYY HH:mm:ss')
            : ''}
        </span>
      ),
    },
    {
      title: 'FACEM / Doctor name',
      key: 'DRName',
      width: 200,
      render: (_, record) => <span>{parseFieldSetup(record?.DRName)}</span>,
    },
    {
      title: 'From number',
      key: 'PHNumber',
      width: 180,
      render: (_, record) =>
        record?.PHNumber && parseFieldSetup(record?.PHNumber) ? (
          <Text copyable>{parseFieldSetup(record.PHNumber)}</Text>
        ) : (
          <span>{parseFieldSetup(record?.PHNumber)}</span>
        ),
    },
    {
      title: 'Call Type',
      key: 'callVideoVoiceType',
      width: 130,
      render: (_, record) => {
        const validCallType = record?.callSid && record?.taskSid;
        const isVoiceCall = record.callSid !== record.taskSid;
        return (
          <span>
            {validCallType &&
              (isVoiceCall ? (
                <Tag icon={<PhoneOutlined />} color="#4eb5b0">
                  Voice Call
                </Tag>
              ) : (
                <Tag icon={<VideoCameraOutlined />} color="#6f86d6">
                  MED Website
                </Tag>
              ))}
          </span>
        );
      },
      filters: [
        {
          text: 'Voice Call',
          value: 'voice',
        },
        {
          text: 'MED Website',
          value: 'website',
        },
      ],
      onFilter: (value, record) => {
        const validCallType = record?.callSid && record?.taskSid;
        const isVoiceCall = record.callSid !== record.taskSid;
        if (validCallType && value === 'voice' && isVoiceCall) return true;
        if (validCallType && value === 'website' && !isVoiceCall) return true;
        return false;
      },
    },
    {
      title: 'Urgency',
      key: 'callType',
      width: 80,
      render: (_, record) => {
        const callType = parseFieldSetup(record?.callType);
        return (
          <span>
            {callType === 'Urgent' ? (
              <Tag color="red">Urgent</Tag>
            ) : (
              callType && <Tag color="green">{callType ?? ''}</Tag>
            )}
          </span>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space size="small">
            <Tooltip title="Open patient form">
              <Button
                shape="circle"
                icon={<FormOutlined />}
                onClick={() => handlePatientIconClick(record)}
                size="small"
              />
            </Tooltip>
            <Tooltip title="Link to patient">
              <Button
                shape="circle"
                icon={<LinkOutlined />}
                onClick={() => showModal(record)}
                size="small"
              />
            </Tooltip>
            <Dropdown menu={menuProps}>
              <Button size="small">
                <Space>
                  Actions
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Space>
        );
      },
      fixed: 'right',
    },
  ];

  const ACTIVE_TABLE_COLUMNS = [
    {
      title: 'Agent name',
      key: 'agentName',
      dataIndex: 'agentName',
      fixed: 'left',
      width: 200,
    },
    {
      title: 'Start time',
      key: 'startTime',
      width: 200,
      render: (_, record) => (
        <span>
          {record?.startTime
            ? dayjs(record?.startTime).utc().format('DD MMM YYYY HH:mm:ss')
            : ''}
        </span>
      ),
    },
    {
      title: 'Patient name',
      key: 'patientFullName',
      render: (_, record) => (
        <span>{`${record?.patientFirstName ? ' ' + parseFieldSetup(record.patientFirstName) : ''}${record?.patientLastName ? ' ' + parseFieldSetup(record.patientLastName) : ''}`}</span>
      ),
      width: 250,
    },
    {
      title: 'DOB',
      key: 'dob',
      dataIndex: 'dob',
      width: 120,
      render: (_, record) => (
        <span>
          {record?.dob ? dayjs(record?.dob).utc().format('DD MMM YYYY') : ''}
        </span>
      ),
    },
    {
      title: 'Multi patient',
      key: 'multiPatient',
      width: 100,
      render: (_, record) => (
        <>
          {record?.isMultiPatient === true && (
            <Tag icon={<DeploymentUnitOutlined />} color="blue">
              Yes
            </Tag>
          )}
          {record?.isMultiPatient === false && (
            <Tag icon={<LineOutlined />} color="#8c8c8c">
              No
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Call stage',
      key: 'callStep',
      dataIndex: 'callStep',
      width: 180,
    },
    {
      title: 'Status',
      key: 'currentStatus',
      render: (_, record) => (
        <span>{translateStatus(record?.currentStatus ?? '')}</span>
      ),
      width: 130,
    },
    {
      title: 'Call Type',
      key: 'callType',
      width: 130,
      render: (_, record) => (
        <>
          {record?.callType === 'voice' && (
            <Tag icon={<PhoneOutlined />} color="#4eb5b0">
              Voice
            </Tag>
          )}
          {record?.callType === 'video' && (
            <Tag icon={<VideoCameraOutlined />} color="#6f86d6">
              Video
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Outcome',
      key: 'outcome',
      dataIndex: 'outcome',
      // width: 200,
    },
  ];
  const handleMenuClick = (e) => {
    console.log('click', e);
  };

  const handlePatientIconClick = (record) => {
    onSelectPatient(record);
    updateModalStatus({ open: true, type: MODAL_TYPES.PATIENT_FORM });
  };

  const items = [
    {
      label: 'Action 2',
      key: '2',
      icon: <PlusOutlined />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return filters?.callsType !== 'active' ? (
    <>
      <Table
        bordered
        size="small"
        rowKey={(record) => `${record.caseID} ${record.callSid}`}
        // onRow={(record, index) => ({
        //   style: {
        //     background: record.callType ? '#fafafa' : 'default',
        //   },
        // })}
        loading={isFetchingPatients}
        dataSource={validPatients}
        columns={HISTORY_TABLE_COLUMNS(showModal)}
        pagination={{
          pageSize: 15,
          size: 'middle',
          position: ['bottomCenter'],
        }}
        sticky={{
          offsetHeader: 0,
        }}
      />
      <LinkPatientModal
        isOpen={isOpen}
        messageApi={messageApi}
        userInfo={userInfo}
        setIsOpen={setIsOpen}
        selectedPatient={selectedPatient}
      />
    </>
  ) : (
    <Table
      bordered
      size="small"
      onRow={(record, index) => ({
        style: {
          background: record.multiIndicator ? '#fafafa' : 'default',
        },
      })}
      loading={isFetchingPatients}
      dataSource={activeCalls && activeCalls?.length ? activeCalls : []}
      columns={ACTIVE_TABLE_COLUMNS}
      pagination={{
        pageSize: 15,
        size: 'middle',
        position: ['bottomCenter'],
      }}
      sticky={{
        offsetHeader: 0,
      }}
    />
  );
};

export default PatientTable;
