import React, { useEffect, useState } from 'react';
import {
  Flex,
  DatePicker,
  AutoComplete,
  Form,
  Input,
  Button,
  Radio,
} from 'antd';
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useFilterStore, usePatientStore } from '../store';
import { getNonEmptyFilter } from '../utils/objectUtils';

const formItemLayout = {
  marginBottom: 0,
};

const Filter = () => {
  const [sitecodeText, setSitecodeText] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);

  const { fetchPatients, fetchActiveCalls, psoFormUpdated, onSelectPatient } =
    usePatientStore((state) => ({
      fetchPatients: state.fetchPatients,
      fetchActiveCalls: state.fetchActiveCalls,
      psoFormUpdated: state.psoFormUpdated,
      onSelectPatient: state.onSelectPatient,
    }));

  const { filters, updateFilters, sitecodeList, fetchSitecodeList } =
    useFilterStore((state) => ({
      filters: state.filters,
      updateFilters: state.updateFilters,
      sitecodeList: state.sitecodeList,
      fetchSitecodeList: state.fetchSitecodeList,
    }));

  const onTextChange = (data) => {
    setSitecodeText(data);
  };

  useEffect(() => {
    if (filters.callsType === 'active') {
      fetchActiveCalls();
    } else {
      const nonEmptyFilters = getNonEmptyFilter(filters);
      fetchPatients(nonEmptyFilters);
      onSelectPatient(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (psoFormUpdated) {
      const nonEmptyFilters = getNonEmptyFilter(filters);
      fetchPatients(nonEmptyFilters);
      onSelectPatient(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [psoFormUpdated]);

  useEffect(() => {
    fetchSitecodeList();
  }, [fetchSitecodeList]);

  return (
    <Flex
      style={{
        width: '100%',
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <Flex
        gap="middle"
        wrap
        justify="flex-start"
        align="flex-end"
        style={{
          width: '100%',
          marginLeft: 20,
        }}
      >
        <Form.Item
          label="Patient First Name"
          name="patientFirstName"
          colon={false}
          layout="vertical"
          labelWrap={true}
          style={formItemLayout}
        >
          <Input
            value={filters.patientFirstName}
            disabled={filters.callsType === 'active'}
            onChange={(e) => updateFilters('patientFirstName', e.target.value)}
          />
        </Form.Item>
        <Form.Item
          style={formItemLayout}
          label="Patient Last Name"
          name="patientLastName"
          colon={false}
          layout="vertical"
          labelWrap={true}
        >
          <Input
            value={filters.patientLastName}
            disabled={filters.callsType === 'active'}
            onChange={(e) => updateFilters('patientLastName', e.target.value)}
          />
        </Form.Item>
        <Form.Item
          style={formItemLayout}
          label="Date of Birth"
          layout="vertical"
        >
          <DatePicker
            maxDate={dayjs(new Date())}
            mode="date"
            showNow={false}
            disabled={filters.callsType === 'active'}
            value={filters.DOB ? dayjs(filters.DOB) : null}
            onChange={(_, dateString) =>
              updateFilters('DOB', dateString || null)
            }
          />
        </Form.Item>
        <Form.Item style={formItemLayout} label="Sitecode" layout="vertical">
          <AutoComplete
            options={sitecodeList ?? []}
            value={sitecodeText}
            onChange={onTextChange}
            disabled={filters.callsType === 'active'}
            style={{
              width: 200,
            }}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            onBlur={() => {
              if (
                !sitecodeList
                  ?.map((option) => option.label)
                  .includes(sitecodeText)
              ) {
                setSitecodeText('');
                updateFilters('sitecode', '');
              }
            }}
            onSelect={(data) => {
              setSitecodeText(data);
              updateFilters('sitecode', data);
            }}
          />
        </Form.Item>
        <Form.Item style={formItemLayout} label="Date Range" layout="vertical">
          <DatePicker.RangePicker
            maxDate={dayjs(new Date())}
            allowClear={false}
            value={filters.dateRange.map((date) => (date ? dayjs(date) : null))}
            onChange={(_, dateString) => updateFilters('dateRange', dateString)}
            disabled={filters.callsType === 'active'}
          />
        </Form.Item>
        <Form.Item style={formItemLayout} label="Call type" layout="vertical">
          <Radio.Group
            defaultValue={filters.callsType}
            buttonStyle="solid"
            value={filters.callsType}
            onChange={(e) => updateFilters('callsType', e.target.value)}
          >
            <Radio.Button value="active">Active</Radio.Button>
            <Radio.Button value="history">History</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {filters.callsType === 'active' ? (
          <Button
            disabled={loadingButton}
            icon={<ReloadOutlined />}
            onClick={() => {
              fetchActiveCalls();
              setLoadingButton(true);
              setTimeout(() => setLoadingButton(false), 6000);
            }}
          >
            Refresh
          </Button>
        ) : (
          <Button
            type="primary"
            disabled={loadingButton}
            icon={<SearchOutlined />}
            onClick={() => {
              const nonEmptyFilters = getNonEmptyFilter(filters);
              fetchPatients(nonEmptyFilters);
              setLoadingButton(true);
              setTimeout(() => setLoadingButton(false), 6000);
            }}
          >
            Search
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default Filter;
