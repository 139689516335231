import { MANDATORY_STRING, OPTIONAL_STRING } from '../constants';

export const parseFieldSetup = (string) => {
  let parsedString = '';
  if (typeof string === 'string') {
    if (string.includes(MANDATORY_STRING) || string.includes(OPTIONAL_STRING)) {
      parsedString = string.split('/')?.[1] || '';
    } else {
      parsedString = string;
    }
  }
  return parsedString;
};

export const translateStatus = (status) => {
  switch (status) {
    case 'created':
      return 'Call in queue';
    case 'assigned':
      return ' Waiting for pick up';
    case 'reserved':
      return 'Talking to patient';
    case 'wrapping':
      return 'Wrapping';
    case 'completed':
      return 'Call completed';
    default:
      return '';
  }
};
